<template>
  <span>
    <b-btn variant="link"
           class="m-0 px-1 line-height-09 font-weight-bold"
           @click="clearSavedSegment()">Change your selected filter to "All Accounts"</b-btn>
    <template v-if="showUpdateWatchedSettingsLink">
      or
      <b-btn variant="link"
             class="m-0 px-1 line-height-09 font-weight-bold"
             @click="goToSettingsPage()">Update your watched accounts settings</b-btn>
    </template>
  </span>
</template>

<script>
export default {
  name: 'savedSegmentClearer',
  computed: {
    activeSegment () {
      return this.$store.getters.activeSegment
    },
    showUpdateWatchedSettingsLink () {
      if (this.$route.path.includes('settings/customer')) {
        return false
      }

      if (this.activeSegment?.name) {
        if (this.activeSegment.name.toLowerCase().includes('my accounts')) {
          return true
        }
      }

      return false
    }
  },
  methods: {
    async goToSettingsPage () {
      await this.clearSavedSegment()

      this.$router.push({ path: '/settings/customer/', query: { customerForm: 'accounts' } })
    },
    async clearSavedSegment () {
      await this.$store.dispatch('clearAllFilters')
    }
  }
}
</script>

<style scoped>
</style>
